@import url('https://fonts.googleapis.com/css2?family=Open+Sans&display=swap');

body {
    margin:0;
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 100vh;
    text-align: center;
    font-family: 'Open Sans', sans-serif;
	margin: 0;
	padding: 0;
	height: 100vh;
	overflow: hidden;
	--sz: 5px; /*** size ***/
	--sp: 10s; /*** speed ***/
	--b1: #3c3532;
	--b2: #1c1b18;
	--b3: #0c0b0a;
	--b4: #0c0b0ae0;
	--c1: #e399ff;
	--ts: 50%/calc(var(--sz) * 17.5) calc(var(--sz) * 29.5);
}
#currencyContainer {
    width:auto;
    height:auto;
    padding:50px 15px;
    box-sizing:border-box;
    border-radius:180px;
	border:0px #fff;
    -webkit-box-shadow: 0 0 100px 130px rgb(255,255,255,0.8);
    box-shadow: 0 0 100px 130px rgb(255,255,255,0.8);
    background:rgba(255,255,255,0.8);
}
#root {
    display: flex;
    justify-content: center;
    align-items: center;
    width:100vw;
	height: 100vh;
	background:
		/* black shadows */
		radial-gradient(circle at 50% 50%, var(--b4) calc(var(--sz) * 1), #fff0 calc(var(--sz) * 8)) var(--ts),
		radial-gradient(circle at 0% 0%, var(--b4) calc(var(--sz) * 1), #fff0 calc(var(--sz) * 8)) var(--ts),
		radial-gradient(circle at 0% 100%, var(--b4) calc(var(--sz) * 1), #fff0 calc(var(--sz) * 8)) var(--ts),
		radial-gradient(circle at 100% 0%, var(--b4) calc(var(--sz) * 1), #fff0 calc(var(--sz) * 8)) var(--ts),
		radial-gradient(circle at 100% 100%, var(--b4) calc(var(--sz) * 1), #fff0 calc(var(--sz) * 8)) var(--ts),
		/* border bottom */
		conic-gradient(from 90deg at 97.5% 67%, var(--c1) 0 87.5deg, #fff0 88deg 100%) var(--ts), /*repeated*/
		conic-gradient(from 90deg at 97.5% 67%, var(--c1) 0 87.5deg, #fff0 88deg 100%) var(--ts),
		conic-gradient(from 182.5deg at 2.5% 67%, #fff0 0 0deg, var(--c1) 0.5deg 90deg, #fff0 0 100%) var(--ts), /*repeated*/
		conic-gradient(from 182.5deg at 2.5% 67%, #fff0 0 0deg, var(--c1) 0.5deg 90deg, #fff0 0 100%) var(--ts), 
		/* border top */
		conic-gradient(from 270deg at 2.5% 33%, var(--c1) 0 87.5deg, #fff0 88deg 100%) var(--ts), /*repeated*/
		conic-gradient(from 270deg at 2.5% 33%, var(--c1) 0 87.5deg, #fff0 88deg 100%) var(--ts),
		conic-gradient(from 2.5deg at 97.5% 33%, #fff0 0 0deg, var(--c1) 0.5deg 90deg, #fff0 0 100%) var(--ts), /*repeated*/
		conic-gradient(from 2.5deg at 97.5% 33%, #fff0 0 0deg, var(--c1) 0.5deg 90deg, #fff0 0 100%) var(--ts),
		/* bottom */
		conic-gradient(from 116.5deg at 50% 85.5%, var(--b1) 0 127deg, #fff0 0 100%) var(--ts), /*repeated*/
		conic-gradient(from 116.5deg at 50% 85.5%, var(--b1) 0 127deg, #fff0 0 100%) var(--ts), /*repeated*/
		conic-gradient(from 116.5deg at 50% 85.5%, var(--b1) 0 127deg, #fff0 0 100%) var(--ts),
		conic-gradient(from 120deg at 50% 83%, var(--c1) 0 120deg, #fff0 0 100%) var(--ts),
		/* top */
		conic-gradient(from -63.5deg at 50% 14.5%, var(--b1) 0 127deg, #fff0 0 100%) var(--ts), /*repeated*/
		conic-gradient(from -63.5deg at 50% 14.5%, var(--b1) 0 127deg, #fff0 0 100%) var(--ts), /*repeated*/
		conic-gradient(from -63.5deg at 50% 14.5%, var(--b1) 0 127deg, #fff0 0 100%) var(--ts),
		conic-gradient(from -60deg at 50% 17%, var(--c1) 0 120deg, #fff0 0 100%) var(--ts),
		/* central gradient */
		conic-gradient(
			from 0deg at 50% 50%, #fff0 0 2deg, 
			var(--b2) 2.5deg 57.5deg, 
			#fff0 58deg 62.5deg, 
			var(--b1) 63deg 117.5deg, 
			#fff0 118deg 122.5deg, 
			var(--b3) 123deg 177.5deg, 
			#fff0 178deg 182deg, 
			var(--b2) 182.5deg 237.5deg, 
			#fff0 0 242.5deg, 
			var(--b1) 243deg 297.5deg, 
			#fff0 298deg 302.5deg, 
			var(--b3) 303deg 357.5deg,
			#fff0 358deg 360deg
		) var(--ts),
		/* bg */
		var(--c1);
	animation: colors var(--sp) linear 0s infinite;
}

@keyframes colors {
	100% { filter: hue-rotate(360deg); }
}

h1 {
    margin:0;
}
h2 {
    color:#666;
	margin: 0 0 20px;
}

input {
    font-family: 'Open Sans', sans-serif;
    font-weight:bold;
    border:1px solid #333;
    border-radius: .3em;
    padding: .25rem;
    width: 7em;
    width:10rem;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
}
input[type=number] {
    background:#333;
    color:#fff;
	padding: 10px;
    font-size: 1rem;
}

select {
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    font-family: 'Open Sans', sans-serif;
    font-weight:bold;
    border-radius: .3em;
    padding: .25rem;
    margin-left: .5rem;
    background:#333;
    color:#fff;
    border:1px solid #333;
	padding: 10px;
    font-size: 1rem;
}

.equals {
    font-weight:bold;
    font-size:2rem
}